<template>
  <div class="container">
    <TheHeader class="header" />
    <main class="main">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.container {
  --header-height: 60px;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  width: clamp(320px, 100%, 1536px);
  height: 100vh;
  margin: 0 auto;
  padding: var(--spacing-0) var(--spacing-200);
}

.header {
  position: sticky;
  z-index: 11;
  top: 0;
  grid-area: header;
}

.main {
  position: relative;

  display: flex;
  grid-area: main;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  padding-top: var(--spacing-200);
}
</style>
